const AUTHENTICATION_GATEWAY = "authentication-gateway";
const JOB_GATEWAY = "job-gateway";
const REPORT_GATEWAY = "report-gateway";

let apiUrl = import.meta.env.VITE_API_URL ?? "";
const injectedApiUrl = "https://api-pre.celerum.online";
if (injectedApiUrl.startsWith("http")) apiUrl = injectedApiUrl;
const googleMapsApiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY ?? "";

export const environment = {
	authenticationApiUrl: `${apiUrl}/${AUTHENTICATION_GATEWAY}`,
	jobApiUrl: `${apiUrl}/${JOB_GATEWAY}`,
	reportApiUrl: `${apiUrl}/${REPORT_GATEWAY}`,
	googleMapsApiKey: `${googleMapsApiKey}`,
};

console.log(environment);
